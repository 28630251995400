<!--
 * @Description: 规则页面
 * @Version: 1.0
 * @Autor: 支磊
 * @Date: 2021-05-25 09:06:10
 * @LastEditors: 支磊
 * @LastEditTime: 2021-11-23 11:19:29
-->
<template>
  <div class="rule">
    <div class="header">
      <div @click="active = toPath('/rule/list')">报警规则</div>
      <div @click="active = toPath('/rule/historylist')">报警记录</div>
      <div @click="active = toPath('/rule/nowlist')">实况信息</div>
      <div class="line" :style="lineStyle"></div>
    </div>
    <div class="main">
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
export default {
  name: "rule",
  components: {},
  data() {
    return {
      active: 0,
    };
  },

  computed: {
    lineStyle() {
      let left = this.active * 80 + 24;
      return `left:${left}px`;
    },
  },
  //监听执行
  watch: {
    $route(to, from) {
      switch (to.path) {
        case "/rule/list":
          this.active = 0;
          break;
        case "/rule/historylist":
          this.active = 1;
          break;
        case "/rule/nowlist":
          this.active = 2;
          break;

        default:
          break;
      }
    },
  },
  mounted() {},
  methods: {},
};
</script>
<style lang="scss" scoped>
.rule {
  height: 100%;
  background: #fbfbfd;
  .header {
    background: #fff;
    display: flex;
    align-items: center;
    height: 54px;
    > div {
      padding-left: 24px;
      font-size: 14px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #313131;
      cursor: pointer;
    }
    position: relative;
    .line {
      position: absolute;
      bottom: 3px;
      left: 0;
      height: 3px;
      width: 55px;
      background: $main_color;
      border-radius: 1px;
      transition: all 0.2s;
    }
  }
  .main {
    margin-top: 10px;
    height: calc(100% - 64px);
    box-shadow: 0 5px 10px 0 rgba(31, 35, 41, 0.1);
    background: #fff;
  }
}
</style>
